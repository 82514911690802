import styled from "@emotion/styled";
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const SocialContainer = styled("div")`
display: inline-block;
float: right;
`;


const SocialFollow = () => {
  return (
    <SocialContainer>
      <a href="https://www.linkedin.com/company/geninquieta"
        className="linkedin social">
        <FaLinkedin size={"2em"} />
      </a>
      <a href="https://www.instagram.com/geninquieta"
        className="instagram social">
        <FaInstagram size={"2em"} />
      </a>
      <a href="https://twitter.com/geninquieta"
        className="twitter social">
        <FaTwitter size={"2em"} />
      </a>
    </SocialContainer>
  );
}

export default SocialFollow;