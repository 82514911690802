import styled from "@emotion/styled";
import { usePageDispatch } from "lib/context/PageContext";
import { signOut, useSession } from "next-auth/react";
import Image from 'next/image';
import React from "react";
import { FaUserCircle } from 'react-icons/fa';
import { GoSignOut } from 'react-icons/go';
import { Role } from "../../lib/api/credentials";
import styles from '../../styles/Navbar.module.css';
import CustomLink from "./CustomLink";
import Maybe from "./Maybe";
import NavLink from "./NavLink";


const NavbarContainer = styled("nav")`
  overflow: hidden;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%; /* Full width */
  padding: 0.5rem 1rem;
  margin: 0px 0px 0px 0px;
  /*background-color: #353482*/
  background-color: white;
  opacity: 0.9;
  z-index:99;
  /*border-bottom: 3px solid black;*/
  text-align:center;
  padding-bottom:5px;
  background: /* gradient can be an image */
    linear-gradient(
      to left, 
      rgba(102,169,141,1) 0%,
      rgba(50,47,128,1) 100%
    )
    left 
    bottom
    white    
    no-repeat; 
  background-size:100% 5px ;/* if linear-gradient, we need to resize it */


  &::after {
    content: "";
    display: table;
    clear: both;
  }

  @media (min-width: 544px) {
    /*border-radius: 0.25rem;*/
  }
`;

const NavbarPresenter = styled("div")`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 544px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 940px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const Logo = styled(CustomLink)`
  float: left;
  font-family: titillium web, sans-serif !important;
  font-size: 1.5rem !important;
  margin-right: 2rem !important;
  padding-top: 0 !important;
  padding-bottom: 0rem;
  /*color: white !important;*/
  color: #5cb85c !important;
`;

const NavbarList = styled("ul")`
  float: right !important;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

`;

const NavbarItem = styled("li")`
  float: left;
  & + & {
    margin-left: 1rem;
  }
`;

const Navbar = () => {

  const setPage = usePageDispatch();
  //const { data: session, status } = useSession()
  const { data: session, status } = useSession()
  //const session = await getSession();
  //const { data: currentUser } = useSWR("user", storage);
  //const isLoggedIn = checkLogin(session);
  console.log("session: ", session)
  console.log("status: ", status)
  const handleClick = React.useCallback(() => setPage(0), []);

  return (
    <NavbarContainer>
      <NavbarPresenter>
        <Logo href="/" as="/" onClick={handleClick}>
          <Image
            src="/logo.png"
            alt="Geninquieta logo"
            width={40}
            height={40}
            // blurDataURL="data:..." automatically provided
            // placeholder="blur" // Optional blur-up while loading
            priority
          />
          <p style={{ float: "right", marginLeft: "10px", marginBottom: "10px", paddingTop: "2px" }}>
            Geninquieta
          </p>
        </Logo>
        <NavbarList>
          {/*
          <NavbarItem>
            <NavLink href="/" as="/" onClick={handleClick}>
              Inicio
            </NavLink>
          </NavbarItem>
          */}
          <Maybe test={session}>
            <Maybe test={session?.role === Role.COMPANY}>
              <NavbarItem>
                <NavLink href="/jobs/new" as="/jobs/new">
                  <i className="ion-compose" />
                  &nbsp;Nueva Oferta
                </NavLink>
              </NavbarItem>

              <NavbarItem>
                <NavLink href="/company/[id]"
                  as={`/company/${session?.company?.username}`}
                  onClick={handleClick}
                >
                  <div className={styles.profilePic + " " + styles.conicGradient}>
                    <Image
                      src={session?.company?.image || "/images/user.jpg"}
                      alt="User profile photo"
                      className={styles.photo}
                      width={50}
                      height={50}
                    // blurDataURL="data:..." automatically provided
                    // placeholder="blur" // Optional blur-up while loading
                    />
                  </div>
                </NavLink>
              </NavbarItem>
            </Maybe>

            <Maybe test={session?.role === Role.USER}>
              <NavbarItem>
                <NavLink href="/user/[id]"
                  as={`/user/${session?.user?.username}`}
                  onClick={handleClick}
                >
                  <div className={styles.profilePic + " " + styles.conicGradient}>
                    <Image
                      src={session?.user?.image || "/images/user.jpg"}
                      alt="User profile photo"
                      className={styles.photo}
                      width={50}
                      height={50}
                    // blurDataURL="data:..." automatically provided
                    // placeholder="blur" // Optional blur-up while loading
                    />
                  </div>
                </NavLink>
              </NavbarItem>
            </Maybe>
            <Maybe test={!session?.role}>
              ERROR
              <NavLink href={``} as={`/`} onClick={signOut}>
                <GoSignOut size={"2em"} />
              </NavLink>
            </Maybe>
            {/*
            <NavbarItem>
              <NavLink href="/user/settings" as="/user/settings">
                <i className="ion-gear-a" />
                &nbsp;Ajustes
              </NavLink>
            </NavbarItem>
            <NavbarItem>
              <NavLink href={``} as={`/`} onClick={signOut}>
                <FontAwesomeIcon icon={faSignOutAlt} size="1x" />
              </NavLink>
            </NavbarItem>
            */}
          </Maybe>
          <Maybe test={status === "unauthenticated"}>

            {/* 
            <NavbarItem>
              <NavLink href="/user/login" as="/user/login">
                Iniciar Sesión
              </NavLink>
            </NavbarItem>
            <NavbarItem>
              <NavLink href="/user/register" as="/user/register">
                Registrarse
              </NavLink>
            </NavbarItem>
            */}
            <NavbarItem>
              <NavLink href="/login" as="/login">
                <FaUserCircle size={"2em"} />
              </NavLink>
            </NavbarItem>

          </Maybe>
        </NavbarList>
      </NavbarPresenter>
    </NavbarContainer>
  );
};

export default Navbar;
