import * as Sentry from "@sentry/nextjs";
import React, { ErrorInfo, ReactNode } from "react";


interface Props {
  children?: ReactNode;
}

interface State {
  error?: Error;
  eventId?: string;
  errorInfo?: ErrorInfo;
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    error: undefined,
    eventId: '',
    errorInfo: undefined,
    // Define a state variable to track whether is an error or not
    hasError: false
  };

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    console.error("Uncaught error:", error, errorInfo);
    console.log({ error, errorInfo })
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as any);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h2>Oops, there is an error!</h2>
          <button type="button" className="btn btn-light" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </button>
          <button className="btn btn-primary" style={{ marginLeft: "1em" }} onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
            Report feedback
          </button>
        </div>
      )
    }
    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;