import { cache } from "@emotion/css";
import { CacheProvider } from "@emotion/react";
import Layout from "components/common/Layout";
import ContextProvider from "lib/context";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import Head from "next/head";
import "styles.css";
import ErrorBoundary from "../components/common/ErrorBoundary";


if (typeof window !== "undefined") {
  require("lazysizes/plugins/attrchange/ls.attrchange.js");
  require("lazysizes/plugins/respimg/ls.respimg.js");
  require("lazysizes");
}

export default function App({ Component, pageProps }: AppProps<{ session: Session }>) {
  return (
    <SessionProvider session={pageProps.session} refetchInterval={5 * 60}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <CacheProvider value={cache}>
        <ContextProvider>
          <ErrorBoundary>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ErrorBoundary>
        </ContextProvider>
      </CacheProvider>
    </SessionProvider>
  );
}
