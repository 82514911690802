
import ErrorBoundary from "./ErrorBoundary";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = ({ children }) => (
  <>
    <ErrorBoundary>
      <Navbar />
    </ErrorBoundary>
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
    <ErrorBoundary>
      <Footer />
    </ErrorBoundary>
  </>
);

export default Layout;
